/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Link as LinkButton,
  useMediaQuery,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import CaregiverImage from "components/CaregiverImage"
import addMinutes from "date-fns/addMinutes"
import {
  fetchUserMeetings as getUserMeetings,
  fetchUserNotifications as getUserNotifications,
} from "redux/user"
import {
  setSessionCount as putSessionCount,
  showAlert as displayAlert,
} from "redux/common"
import { useTheme } from "@emotion/react"
import { navigationValues } from "utils/navigationValues"
import classNames from "classnames"
import { getUserSessionCount } from "api/getUserSessionCount"
import { cancelMeeting } from "api/cancelMeeting"
import { unmatchCaregiver } from "api/unmatchCaregiver"
import VideoPaymentModal from "components/VideoPaymentModal"
import FrikortModal from "components/FrikortModal"
import { sessionType } from "utils/sessionType"
import { getFormattedDateAndTime } from "utils/getFormattedDateAndTime"
import { getFirstLetter } from "utils/getFirstLetter"
import VideoMeetingModal from "./VideoMeetingModal"
import VideoInfoModal from "./VideoInfoModal"
import NewVideoBookingModal from "./NewVideoBookingModal"
import MeetingBookedByCaregiverModal from "./MeetingBookedByCaregiverModal"
import ReservedTimeRejectionConfirmationDialog from "./ReservedTimeRejectionConfirmationDialog"
import CancelMeetingConfirmationDialog from "./CancelMeetingConfirmationDialog"
import UnmatchCaregiverConfirmationDialog from "./UnmatchCaregiverConfirmationDialog"

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: 270,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: theme.palette.lighter_gray,
    borderLeft: `0.5px solid ${theme.palette.gray}`,
    borderRight: `0.5px solid ${theme.palette.gray}`,
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: 180,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "none",
      borderRight: "none",
      minHeight: "175px",
      padding: theme.spacing(1, 1, 1, 2.5),
    },
    [theme.breakpoints.down(380)]: {
      minHeight: "195px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down(380)]: {
      marginTop: theme.spacing(3),
    },
  },
  title: {
    alignSelf: "flex-start",
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textSpacing: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  text: {
    color: theme.palette.dark_gray,
    textDecoration: "none",
    fontSize: "0.8rem",
  },
  nextBookedTimeText: {
    marginTop: theme.spacing(1),
    fontSize: "0.8rem",
  },
  nextBookedTime: {
    fontWeight: "bold",
    fontSize: "0.8rem",
    whiteSpace: "pre-line",
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  linkContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  link: {
    cursor: "pointer",
  },
  button: {
    border: "none",
    width: "100%",
    borderRadius: "1.25rem",
    background: [theme.palette.primary.main],
    marginTop: theme.spacing(2),
    maxWidth: "200px",
    "&:hover": {
      background: [theme.palette.primary.dark],
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
  warningButton: {
    background: [theme.palette.warning.dark],
    "&:hover": {
      background: [theme.palette.warning.darker],
    },
  },
  buttonText: {
    textTransform: "none",
    color: [theme.palette.primary.contrastText],
    fontWeight: 500,
    fontSize: "0.8rem",
  },
  caregiverInfo: {
    margin: theme.spacing(3, 2, 0, 2),
    width: "100%",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  hidden: { visibility: "hidden" },
  linkButton: {
    textDecoration: "underline",
    marginTop: theme.spacing(1),
    width: "100%",
    maxWidth: "200px",
    "& span": {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
}))

const Sidebar = ({
  userId,
  hasUserFinishedOnboarding,
  caregiver,
  videoMeeting,
  previousVideoMeeting,
  fetchUserMeetings,
  fetchUserNotifications,
  treatmentStageImage,
  setSessionCount,
  showAlert,
}) => {
  const theme = useTheme()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { loading, error, data } = caregiver
  const { firstName = "", lastName = "", id: caregiverId } = data || {}
  const [navigationValue, setNavigationValue] = useState(null)
  const [openVideoMeetingModal, setOpenVideoMeetingModal] = useState(false)
  const [openVideoInfoModal, setOpenVideoInfoModal] = useState(false)
  const [
    openMeetingBookedByCaregiverModal,
    setOpenMeetingBookedByCaregiverModal,
  ] = useState(false)
  const [meetingBookedByCaregiver, setMeetingBookedByCaregiver] = useState(null)
  const [openNewVideoBookingModal, setOpenNewVideoBookingModal] =
    useState(false)
  const [
    openReservedTimeRejectionConfirmationModal,
    setOpenReservedTimeRejectionConfirmationModal,
  ] = useState(false)
  const videoMeetingFormattedStart = videoMeeting?.start
    ? getFormattedDateAndTime(videoMeeting.start)
    : null
  const [
    openCancelMeetingConfirmationDialog,
    setOpenCancelMeetingConfirmationDialog,
  ] = useState(false)
  const [meetingCancellationError, setMeetingCancellationError] = useState(null)
  const [
    openUnmatchCaregiverConfirmationDialog,
    setOpenUnmatchCaregiverConfirmationDialog,
  ] = useState(false)
  const [
    unmatchCaregiverConfirmationDialogError,
    setUnmatchCaregiverConfirmationDialogError,
  ] = useState(null)
  const [openVideoPaymentModal, setOpenVideoPaymentModal] = useState(false)
  const [openFrikortModal, setOpenFrikortModal] = useState(false)

  useEffect(() => {
    if (!hasUserFinishedOnboarding) {
      setNavigationValue(navigationValues.VIDEO)
    } else {
      const path = location.pathname
      switch (path) {
        case "/":
          setNavigationValue(navigationValues.HOME)
          break
        case "/video":
          setNavigationValue(navigationValues.VIDEO)
          break
        case "/messages":
          setNavigationValue(navigationValues.MESSAGES)
          break
        default:
          setNavigationValue(null)
          break
      }
    }
  }, [location])

  const handleLeaveVideoMeetingConfirmation = async () => {
    setOpenVideoMeetingModal(false)
    const { data: totalSessionCount } = await getUserSessionCount(
      userId,
      caregiverId
    )
    setSessionCount(totalSessionCount)
    const meetingsData = await fetchUserMeetings()
    const notificationsData = await fetchUserNotifications()
    if (
      notificationsData?.payload?.unreadMessagesNumber ||
      notificationsData?.payload?.unfilledFormsNumber
    ) {
      showAlert({
        type: "info",
        message: (
          <Typography>
            Du har fått nya{" "}
            <LinkButton
              href={`${window.location.origin}/messages`}
              underline="always"
              color="white"
            >
              meddelanden eller formulär
            </LinkButton>{" "}
            att fylla i.
          </Typography>
        ),
      })
      sessionStorage.setItem("shownUnreadMessagesNotification", true)
    }
    if (
      meetingsData?.payload?.meeting?.start &&
      meetingsData?.payload?.meeting?.start.toString() !==
        videoMeeting?.start.toString()
    ) {
      setMeetingBookedByCaregiver({
        start: meetingsData.payload.meeting.start,
        paid: meetingsData.payload.meeting.paid,
        paymentLink: meetingsData.payload.meeting.paymentLink,
      })
      setTimeout(() => setOpenMeetingBookedByCaregiverModal(true), 1000)
    } else if (addMinutes(new Date(videoMeeting?.start), 24) < new Date()) {
      setTimeout(() => setOpenNewVideoBookingModal(true), 1000)
    }
  }

  const handleMeetingCancellation = async ({ isLateCancellation }) => {
    try {
      await cancelMeeting(videoMeeting?.id)
      await fetchUserMeetings()
      setOpenCancelMeetingConfirmationDialog(false)

      if (!previousVideoMeeting?.start) {
        handleUnmatchFromCaregiver()
      }

      const afterMeetingCancellationMessage = isLateCancellation
        ? "Ditt videosamtal har avbokats. Du är välkommen att boka en ny tid."
        : videoMeeting?.payingUser
        ? "Ditt videosamtal har avbokats och din patientavgift kommer återbetalas. Du är välkommen att boka en ny tid."
        : "Ditt videosamtal har avbokats. Du är välkommen att boka en ny tid."
      showAlert({
        type: "success",
        message: afterMeetingCancellationMessage,
      })
    } catch (error) {
      setMeetingCancellationError(
        "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
      )
    }
  }

  const handleOpenUnmatchCaregiverConfirmationDialog = () => {
    setUnmatchCaregiverConfirmationDialogError(null)
    setOpenUnmatchCaregiverConfirmationDialog(true)
  }

  const handleUnmatchFromCaregiver = async () => {
    try {
      await unmatchCaregiver(userId)
      window.location.replace("/")
    } catch (error) {
      setUnmatchCaregiverConfirmationDialogError(
        "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
      )
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const SidebarContent = () => {
    if (loading) {
      return <CircularProgress size={50} color="primary" />
    }

    return (
      <Box className={classes.container}>
        <CaregiverImage />
        <Box className={classes.caregiverInfo}>
          <Typography
            className={`${classes.textSpacing} ${classes.name}`}
          >{`${firstName} ${getFirstLetter(lastName)}`}</Typography>
          <Typography className={classes.text}>Leg. Psykolog</Typography>
          <Typography className={classes.nextBookedTimeText}>
            {!videoMeeting?.start || videoMeeting.paid
              ? "Bokat videosamtal:"
              : "Reserverad tid:"}
          </Typography>
          <Typography
            className={classes.nextBookedTime}
            fontStyle={`${videoMeeting?.start ? "" : "italic"}`}
          >
            {videoMeetingFormattedStart || "Ingen bokad tid"}
          </Typography>
          <Button
            sx={{
              backgroundColor: "#4287f5 !important",
            }}
            onClick={() =>
              videoMeeting?.start
                ? videoMeeting?.paid
                  ? setOpenVideoInfoModal(true)
                  : setOpenVideoPaymentModal(true)
                : navigate("/video")
            }
            className={classNames(classes.button, {
              [classes.hidden]:
                navigationValue === navigationValues.VIDEO &&
                !videoMeeting?.start,
              [classes.warningButton]:
                videoMeeting?.start && !videoMeeting?.paid,
            })}
            variant="contained"
          >
            <Typography className={classes.buttonText} variant="h6">
              {videoMeeting?.start
                ? videoMeeting?.paid
                  ? "Till videosamtal"
                  : "Bekräfta bokning"
                : "Boka video"}
            </Typography>
          </Button>
          {[
            navigationValues.HOME,
            navigationValues.VIDEO,
            navigationValues.MESSAGES,
          ].includes(navigationValue) &&
            videoMeeting?.start &&
            new Date(videoMeeting?.start) > new Date() &&
            videoMeeting?.paid && (
              <LinkButton
                component="button"
                className={classes.linkButton}
                onClick={() => setOpenCancelMeetingConfirmationDialog(true)}
              >
                <Typography variant="caption">Avboka</Typography>
              </LinkButton>
            )}
          {navigationValue === navigationValues.HOME && !videoMeeting?.start && (
            <LinkButton
              component="button"
              className={classes.linkButton}
              onClick={() => handleOpenUnmatchCaregiverConfirmationDialog()}
            >
              <Typography variant="caption">Byt psykolog</Typography>
            </LinkButton>
          )}
        </Box>
        <VideoInfoModal
          openModal={openVideoInfoModal}
          onClose={() => setOpenVideoInfoModal(false)}
          onVideoMeetingOpen={() => {
            setOpenVideoInfoModal(false)
            setOpenVideoMeetingModal(true)
          }}
        />
        <VideoMeetingModal
          videoMeetingId={videoMeeting?.id}
          openModal={openVideoMeetingModal}
          videoMeetingUrl={videoMeeting?.url}
          onLeaveVideoMeetingConfirmation={handleLeaveVideoMeetingConfirmation}
        />
        <NewVideoBookingModal
          openDialog={openNewVideoBookingModal}
          onClose={() => setOpenNewVideoBookingModal(false)}
          onConfirm={() => {
            setOpenNewVideoBookingModal(false)(
              location.pathname !== "video" ? navigate("/video") : null
            )
          }}
        />
        <MeetingBookedByCaregiverModal
          openDialog={openMeetingBookedByCaregiverModal}
          onClose={() => setOpenMeetingBookedByCaregiverModal(false)}
          onReservedTimeRejection={() => {
            setOpenMeetingBookedByCaregiverModal(false)
            setOpenReservedTimeRejectionConfirmationModal(true)
          }}
          openPaymentDialog={() => {
            setOpenMeetingBookedByCaregiverModal(false)
            setOpenVideoPaymentModal(true)
          }}
          meeting={meetingBookedByCaregiver}
        />
        <ReservedTimeRejectionConfirmationDialog
          openDialog={openReservedTimeRejectionConfirmationModal}
          onClose={() => {
            setMeetingBookedByCaregiver(null)
            setOpenReservedTimeRejectionConfirmationModal(false)
          }}
          openPaymentDialog={() => {
            setOpenReservedTimeRejectionConfirmationModal(false)
            setOpenVideoPaymentModal(true)
          }}
        />
        <CancelMeetingConfirmationDialog
          openDialog={openCancelMeetingConfirmationDialog}
          onClose={() => {
            setOpenCancelMeetingConfirmationDialog(false)
            setMeetingCancellationError(null)
          }}
          onMeetingCancellation={handleMeetingCancellation}
          error={meetingCancellationError}
          videoMeetingStart={videoMeeting?.start}
        />
        <UnmatchCaregiverConfirmationDialog
          openDialog={openUnmatchCaregiverConfirmationDialog}
          onClose={() => setOpenUnmatchCaregiverConfirmationDialog(false)}
          onUnmatch={handleUnmatchFromCaregiver}
          error={unmatchCaregiverConfirmationDialogError}
        />
        <VideoPaymentModal
          open={openVideoPaymentModal}
          close={() => {
            setOpenVideoPaymentModal(false)
          }}
          onPaymentDecision={() => {
            setOpenVideoPaymentModal(false)
            setOpenFrikortModal(true)
          }}
          showAlert={({ alertType, message }) =>
            showAlert({ alertType, message })
          }
          isFirstChatOrVideoSession={!previousVideoMeeting?.start}
          videoMeetingPaymentLink={videoMeeting?.paymentLink}
        />
        <FrikortModal
          open={openFrikortModal}
          close={() => setOpenFrikortModal(false)}
          goBack={() =>
            openFrikortModal
              ? (setOpenFrikortModal(false), setOpenVideoPaymentModal(true))
              : null
          }
          sessionType={sessionType.VIDEO}
          isFirstChatOrVideoSession={!previousVideoMeeting?.start}
          videoMeetingId={videoMeeting?.id}
        />
      </Box>
    )
  }

  if (
    error ||
    (isWidthSmDown &&
      !videoMeeting?.start &&
      navigationValue === navigationValues.VIDEO) ||
    ((!navigationValue || navigationValue === navigationValues.HOME) &&
      isWidthSmDown &&
      !treatmentStageImage)
  ) {
    return null
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title} color="primary">
        {navigationValue === navigationValues.HOME
          ? "Min psykolog"
          : navigationValue === navigationValues.VIDEO
          ? "Videosamtal"
          : "Meddelanden"}
      </Typography>
      <SidebarContent />
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { caregiver, user, common } = state
  const {
    meeting: videoMeeting,
    previousVideoMeeting,
    id: userId,
    onboardingFinished: hasUserFinishedOnboarding,
  } = user
  const { treatmentStageImage } = common
  return {
    userId,
    hasUserFinishedOnboarding,
    caregiver,
    videoMeeting,
    previousVideoMeeting,
    treatmentStageImage,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserMeetings: () => dispatch(getUserMeetings()),
  fetchUserNotifications: () => dispatch(getUserNotifications()),
  setSessionCount: (sessionCount) => dispatch(putSessionCount(sessionCount)),
  showAlert: (data) => dispatch(displayAlert(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
